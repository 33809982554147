<template>
  <footer class="w-full inline-flex pt-2 pb-5 pl-10 mb-10 bg-primary-light border select-none">
    <div class="w-1/2">
      <h3 class="text-sm">{{ $t('order_footer.internal_processing_mark', { locationName }) }}</h3>
      <table class="w-full border-collapse">
        <thead>
          <tr>
            <td class="w-150"></td>
            <td class="text-sm text-center bg-gray-200 border">{{ $t('order_footer.in') }}</td>
            <td class="text-sm text-center bg-gray-200 border">{{ $t('order_footer.out') }}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-sm">{{ $t('order_footer.door_nr') }}</td>
            <td class="bg-gray-200 border"></td>
            <td class="bg-gray-200 border"></td>
          </tr>
          <tr>
            <td class="text-sm">{{ $t('order_footer.delivery_date') }}</td>
            <td class="bg-gray-200 border"></td>
            <td class="bg-gray-200 border"></td>
          </tr>
          <tr>
            <td class="text-sm">{{ $t('order_footer.area_door') }}</td>
            <td class="bg-gray-200 border"></td>
            <td class="bg-gray-200 border"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="w-1/2 flex items-center">
      <p class="text-sm ml-40 mr-20">
        Los Nr.
      </p>
      <div class="flex-grow self-start">
        <div class="flex items-center flex-wrap mt-5">
          <div class="flex items-center mr-7">
            <div class="h-15 w-15 border"></div>
            <p class="text-xs ml-5">{{ $t('order_footer.car') }}</p>
          </div>
          <div class="flex items-center mr-7">
            <div class="h-15 w-15 border"></div>
            <p class="text-xs ml-5">{{ $t('order_footer.trailer') }}</p>
          </div>
          <div class="flex items-center">
            <div class="h-15 w-15 border"></div>
            <p class="text-xs ml-5">{{ $t('order_footer.lorry') }}</p>
          </div>
        </div>
        <div class="h-40 w-full mt-15 bg-gray-200 border"></div>
      </div>
      <div class="flex flex-col items-center mt-10 ml-10 mr-15">
        <div class="h-55 w-55 bg-gray-200 border rounded-full"></div>
        <p class="text-xs mt-5">{{ $t('order_footer.loading_position') }}</p>
      </div>
    </div>
  </footer>
</template>


<script>
export default {
  props: {
    locationName: {
      type: String,
      default: 'Wellhausen',
    },
  },
};
</script>
