<template>
  <div>
    <datalist id="material-designations">
      <option v-for="(materialDesignation, i) in materialDesignations" :key="`material-designation-${i}`">{{
        materialDesignation
      }}
      </option>
    </datalist>
    <div class="w-full flex">
      <p class="form-label-primary flex-shrink-0 w-6/100 align-top table-cell text-xs text-center pt-22 border">
        {{ $t('order_metal_components.internal') }}
      </p>
      <div class="flex-grow border-t">
        <div class="form-label-primary w-full flex font-bold text-xs border-b">
          <p class="w-1/2 text-center py-7 border-r">
            {{ $t('order_metal_components.per_order') }} <span class="underline px-2">{{
              $t('order_metal_components.per_order_note_stress')
            }}</span> {{
  $t('order_metal_components.per_order_note_end')
}}
          </p>
          <p class="w-1/2 text-center py-7">
            {{ zyncLine }}
          </p>
        </div>
        <div class="w-full flex">
          <p class="form-label-secondary flex-shrink-0 w-5/100 border-b border-r">
            {{ $t('order_metal_components.position') }}</p>
          <p class="form-label-secondary flex-shrink-0 w-7/100 border-b border-r">{{
            $t('order_metal_components.quantaty')
          }}</p>
          <p class="form-label-secondary flex-shrink-0 w-26/100 border-b border-r">{{
            $t('order_metal_components.description')
          }}</p>
          <p class="form-label-secondary flex-shrink-0 w-5/100 border-b border-r">{{
            $t('order_metal_components.image')
          }}</p>
          <p class="form-label-secondary flex-grow border-b border-r">{{ $t('order_metal_components.dimentions') }}</p>
          <p class="form-label-secondary flex-shrink-0 w-9/100 border-b border-r">{{
            $t('order_metal_components.length')
          }}</p>
          <p class="form-label-secondary flex-shrink-0 w-9/100 border-b border-r">{{
            $t('order_metal_components.width')
          }}</p>
          <p class="form-label-secondary flex-shrink-0 w-9/100 border-b">{{ $t('order_metal_components.depth') }}</p>
        </div>
        <SmoothReflow tag="div" :options="{
          property: 'height',
          transition: 'height 300ms cubic-bezier(0.77, 0, 0.175, 1)',
          transitionEvent: {
            selector: '.row',
            propertyName: 'opacity',
          },
        }" class="relative w-full">
          <TransitionGroup name="transition-metal-component-row" tag="div">
            <div v-for="(metalComponent, i) in metalComponents" :key="`metal-component-${metalComponent.id}`"
              class="row flex border-b" @mouseover="hoveredRowIndex = i" @mouseleave="hoveredRowIndex = -1">
              <label class="flex-shrink-0 w-5/100 border-r">
                <span class="sr-only">{{ $t('order_metal_components.position') }}</span>
                <input disabled v-model.number="metalComponent.position" v-mask="'###'" type="tel"
                  class="input-text h-full w-full text-center" @keydown.enter.prevent @input="onInput(i)">
              </label>
              <label class="flex-shrink-0 w-7/100 border-r">
                <span class="sr-only">{{ $t('order_metal_components.quantaty') }}</span>
                <input disabled v-model.number="metalComponent.count" v-mask="'#####'" type="tel"
                  class="input-text h-full w-full text-center" @keydown.enter.prevent @input="onInput(i)">
              </label>
              <label class="flex-shrink-0 w-26/100 border-r">
                <span class="sr-only">{{
                  $t('order_metal_components.description')
                }}</span>
                <input disabled v-model="metalComponent.materialDesignation" list="material-designations"
                  class="input-text h-full w-full text-center" type="text" @keydown.enter.prevent @input="onInput(i)">
              </label>
              <div class="flex-shrink-0 w-5/100 flex items-center border-r">
                <UIInputFile disabled v-model="metalComponent.imageFile" accept=".png, .jpg, .jpeg"
                  class="grid-last-row w-full flex flex-col items-center justify-center px-2" @keydown.enter.prevent
                  @input="onImageFileInput(i, $event)">
                  <template #default="{ selectFile, removeFile }">
                    <UIDropdown :is-disabled="true" trigger-classes="button button-secondary button-sm button-text button-rounded-full flex
                      items-center" class="ml-1">
                      <template #trigger>
                        <span class="h-26 w-26">
                          <IconDocumentAdd v-if="!metalComponent.imageURL && !metalComponent.imageFile"
                            class="icon-document-add print:hidden" @click.stop="selectFile" />
                          <IconPhoto v-else />
                        </span>
                      </template>
                      <div class="rounded-lg shadow-xs">
                        <Transition name="transition-fade-slow">
                          <div v-if="metalComponent.imageURL" class="flex flex-col p-10 bg-white rounded-lg shadow-xl">
                            <div class="relative">
                              <img :src="metalComponent.imageURL"
                                :alt="metalComponent.imageFile && metalComponent.imageFile.name" class="min-w-200 max-w-250 border border-gray-200 rounded cursor-pointer
                                transition-opacity duration-200 hover:opacity-75" @click="selectFile">
                              <div class="absolute top-0 right-0 h-30 w-30 p-5 mt-5 mr-5 bg-secondary rounded-full
                                cursor-pointer" @click="selectFile">
                                <IconEdit class="text-white" />
                              </div>
                            </div>
                            <button disabled type="button" class="button button-danger button-lg button-text mt-5"
                              @click="removeFile">
                              {{ $t('order_item.delete') }}
                            </button>
                          </div>
                        </Transition>
                      </div>
                    </UIDropdown>
                  </template>
                </UIInputFile>
              </div>
              <ul class="flex-grow border-r print:py-5">
                <SmoothReflow tag="li" :options="{
                  property: 'height',
                  transition: 'height 300ms cubic-bezier(0.77, 0, 0.175, 1)',
                }">
                  <UIDropdownProfile :is-disabled="true" v-for="(profDims, j) in metalComponent.profilesWithDimensions"
                    :key="`${metalComponent.id}-${j}`" :profile-type.sync="profDims.type"
                    :profile-dimensions.sync="profDims.dimensions" :show-input-controls="hoveredRowIndex === i"
                    :class="{ 'print:hidden': !(profDims.type || profDims.dimensions) }"
                    @add="addProfile(metalComponent)" @clear="clearProfile(metalComponent, j)" @input="onInput(i)"
                    @image-loaded="loadedProfileImage" />
                </SmoothReflow>
              </ul>
              <label class="flex-shrink-0 w-9/100 border-r">
                <span class="sr-only">{{ $t('order_metal_components.length') }}</span>
                <input disabled v-model="metalComponent.length" type="text" class="input-text h-full w-full text-center"
                  @keydown.enter.prevent @input="onInput(i)">
              </label>
              <label class="flex-shrink-0 w-9/100 border-r">
                <span class="sr-only">{{ $t('order_metal_components.width') }}</span>
                <input disabled v-model="metalComponent.width" type="text" class="input-text h-full w-full text-center"
                  @keydown.enter.prevent @input="onInput(i)">
              </label>
              <label class="flex-shrink-0 w-9/100">
                <span class="sr-only">{{ $t('order_metal_components.depth') }}</span>
                <input disabled v-model="metalComponent.depth" type="text" class="input-text h-full w-full text-center"
                  @keydown.enter.prevent @input="onInput(i)">
              </label>
              <Transition name="transition-fade-slow">
                <button disabled v-show="metalComponent.clearRowIsVisible"
                  class="button absolute -right-35 h-34 w-34 text-white bg-secondary rounded-r-md print:hidden"
                  @click="clearRowPress(metalComponent, i)">
                  <IconClose class="h-full mt-1" />
                </button>
              </Transition>
            </div>
          </TransitionGroup>
        </SmoothReflow>
      </div>
      <p class="form-label-primary flex-shrink-0 w-6/100 align-top table-cell text-xs text-center pt-22 border">
        {{ $t('order_metal_components.internal') }}
      </p>
    </div>

    <div class="mt-4 pb-5 print:hidden">
      <button disabled type="button" :title="maxRowLimitReached ? 'Maximum 20 Positionen' : 'Press to enter a Position'"
        class="button button-secondary button-lg button-text flex items-center justify-center mx-auto"
        :class="{ 'cursor-not-allowed': maxRowLimitReached }" @click="addRow">
        <IconAdd class="h-30 w-30 mr-5 -ml-5" />
        {{ $t('order_metal_components.add_order_line') }}
      </button>
    </div>
  </div>
</template>

<script>
import { v4 as UUID } from 'uuid';
import SmoothReflow from '@/components/SmoothReflow';
import IconAdd from '@/components/icon/IconAdd';
import IconDocumentAdd from '@/components/icon/IconDocumentAdd';
import IconEdit from '@/components/icon/IconEdit';
import IconPhoto from '@/components/icon/IconPhoto';
import IconClose from '@/components/icon/IconClose';
import UIInputFile from '@/components/UI/input/UIInputFileNew';
import UIDropdown from '@/components/UI/UIDropdown';
import UIDropdownProfile from '@/components/UI/UIDropdownProfile';
import materialDesignations from '@/helpers/materialDesignations';
import {
  minMetalComponentRows,
  maxMetalComponentRows,
  maxProfilesPerMetalComponent,
  defaultProfileTypeWithDimensions,
  defaultMetalComponent,
} from '@/helpers/metalComponent';

export default {
  model: {
    prop: 'metalComponents',
    event: 'change',
  },
  props: {
    metalComponents: {
      type: Array,
      default: () => [],
    },
    locationId: {
      type: String,
      default: ""
    },
  },
  components: {
    SmoothReflow,
    IconAdd,
    IconDocumentAdd,
    IconEdit,
    IconPhoto,
    IconClose,
    UIInputFile,
    UIDropdown,
    UIDropdownProfile,
  },
  data: () => ({
    materialDesignations: process.env.NODE_ENV === 'production' ? Object.freeze(materialDesignations.sort()) : materialDesignations.sort(),
    hoveredRowIndex: -1,
    loadedProfileImagesCount: 0,
  }),
  computed: {
    zyncLine() {
      if (this.locationId === 'oberndorf') {
        return `Zinkbad: 8.0 m Länge, 1.4 m Breite, 2.8 m Höhe`
      } else {
        return this.$t('order_metal_components.tin_bath')
      }
    },
    maxRowLimitReached() {
      return this.metalComponents.length >= maxMetalComponentRows;
    },
  },
  methods: {
    onImageFileInput(i, imageFile) {
      if (!imageFile) {
        this.metalComponents[i].imageURL = '';
        this.metalComponents[i].imageStoragePath = '';
        this.metalComponents[i].clearRowIsVisible = this.inputsAreFilled(this.metalComponents[i]);
        return;
      }

      this.metalComponents[i].imageURL = URL.createObjectURL(imageFile);
      this.metalComponents[i].clearRowIsVisible = true;
    },
    addProfile(metalComponent) {
      if (metalComponent.profilesWithDimensions.length >= maxProfilesPerMetalComponent
        || !metalComponent.profilesWithDimensions[metalComponent.profilesWithDimensions.length - 1].type
        || !metalComponent.profilesWithDimensions[metalComponent.profilesWithDimensions.length - 1].dimensions) return;

      metalComponent.profilesWithDimensions.push({
        type: '',
        dimensions: '',
      });
    },
    clearProfile(metalComponent, profileIndex) {
      // Last profile selector
      if (profileIndex === metalComponent.profilesWithDimensions.length - 1) {
        this.$set(metalComponent.profilesWithDimensions, profileIndex, {
          ...defaultProfileTypeWithDimensions,
        });
      } else {
        metalComponent.profilesWithDimensions.splice(profileIndex, 1);

        // Add new profile if the last profile is not a "new profile" already
        const lastProfile = metalComponent.profilesWithDimensions[metalComponent.profilesWithDimensions.length - 1];
        if (metalComponent.profilesWithDimensions.length === maxProfilesPerMetalComponent - 1
          && lastProfile.type && lastProfile.dimensions) {
          metalComponent.profilesWithDimensions.push({
            type: '',
            dimensions: '',
          });
        }
      }
    },
    addRow() {
      if (this.maxRowLimitReached) return;
      this.metalComponents.push({
        id: UUID(),
        clearRowIsVisible: true,
        ...defaultMetalComponent,
        profilesWithDimensions: [{ ...defaultProfileTypeWithDimensions }],
      });
    },
    onInput(i) {
      if (i < minMetalComponentRows) {
        this.metalComponents[i].clearRowIsVisible = this.inputsAreFilled(this.metalComponents[i]);
        return;
      }
      this.metalComponents[i].clearRowIsVisible = true;
    },
    inputsAreFilled(metalComponent) {
      return !!(metalComponent.position
        || metalComponent.count
        || metalComponent.materialDesignation
        || metalComponent.profilesWithDimensions[0].type
        || metalComponent.profilesWithDimensions[0].dimensions
        || metalComponent.length
        || metalComponent.width
        || metalComponent.depth
      );
    },
    clearRowPress(metalComponent, i) {
      if (i <= (minMetalComponentRows - 1) && this.metalComponents.length <= minMetalComponentRows) {
        this.$set(this.metalComponents, i, {
          id: metalComponent.id,
          clearRowIsVisible: false,
          ...defaultMetalComponent,
          profilesWithDimensions: [{ ...defaultProfileTypeWithDimensions }],
        });
        return;
      }
      this.metalComponents.splice(i, 1);
    },
    loadedProfileImage() {
      this.loadedProfileImagesCount += 1;
      if (this.metalComponents
        .flatMap((m) => m.profilesWithDimensions.filter((p) => p.type || p.dimensions))
        .length === this.loadedProfileImagesCount) {
        this.$emit('images-loaded');
      }
    },
  },
};
</script>

<style>
.icon-document-add {
  & .primary {
    @apply fill-gray-200;
  }

  & .secondary {
    @apply fill-secondary;
  }
}

.icon-photo {
  & .primary {
    @apply fill-gray-400;
  }

  & .secondary {
    @apply fill-secondary;
  }
}

.transition-metal-component-row-enter,
.transition-metal-component-row-leave-to {
  @apply opacity-0;

  border-color: theme('borderColor.transparent');
}

.transition-metal-component-row-enter-active {
  @apply duration-500 ease-in-quart;

  transition-property: opacity, border-color;
}

.transition-metal-component-row-leave-active {
  @apply duration-500 ease-out-quart;

  transition-property: opacity, border-color;
}

.transition-metal-component-row-enter-to,
.transition-metal-component-row-leave {
  @apply opacity-100;

  border-color: theme('borderColor.default');
}
</style>
