<template class="h-full">
  <div>
    <UIModal v-model="modal.isShowing" title="Keine Behandlung" content-description="Keine Behandlung"
      class="print:hidden">
      <template #header="{ hideModal }">
        <div class="flex items-center justify-between pl-22 pr-15 my-15 tablet:pl-25 tablet:mt-20">
          <h2 :title="modalTitle" class="font-medium text-xl leading-tight line-clamp-2">
            {{ modalTitle }}
          </h2>
          <button v-if="!orderSaved && modal.type !== 'empty-print'" disabled class="self-start flex-shrink-0 hidden h-40 w-40 flex items-center justify-center -my-5 ml-15 rounded-full
            focus:outline-none tablet:block any-pointer-fine:block" :class="{
              'focus:bg-gray-200 hover:bg-gray-200': !modal.isLoading,
              'opacity-75 cursor-not-allowed': modal.isLoading,
            }" @click="hideModal">
            <IconClose class="h-full w-full text-2xl text-gray-600" />
          </button>
        </div>
      </template>
      <template #default>
        <div v-if="modal.type === 'empty-print' || orderSaved"
          class="flex flex-col items-center px-25 mb-10 tablet:mb-20">
          <div>
            Bitte drucken Sie den E-Lieferschein aus und geben Sie diesen dem {{ driverLine }} mit.
          </div>
          <div class="text-center mt-10">
            Achtung: Der ausgedruckte und übermittelte Lieferschein kann nicht mehr geändert werden.
          </div>
          <button disabled class="button button-primary button-lg button-filled flex items-center mt-40 mb-10"
            @click="successfulSubmitPrint">
            <IconPrint class="icon-print h-20 w-20 text-gray-600 mr-10" />
            {{ $t('order_edit.print_and_send') }}
          </button>
          <button disabled class="button button-secondary button-lg button-text flex items-center"
            @click="modal.isShowing = false">
            {{ $t('generic_crud_labels.cancel') }}
          </button>
        </div>
        <div v-else-if="modal.type === 'no-treatment'" class="flex flex-col items-center px-25 mb-10 tablet:mb-20">
          <div class="relative h-74 w-74 flex items-center justify-center bg-gray-200 rounded-full">
            <IconDocumentNotes class="icon-document-notes h-40" />
          </div>
          <p v-if="!modal.showNexButton" class="mt-20">
            {{ $t('order_body.no_treatment_warning') }}
          </p>
          <p v-else class="mt-20">
            {{ $t('order_body.no_treatment_warning_continue') }}
          </p>
        </div>
        <div v-else-if="modal.type === 'empty-shipping'" class="flex flex-col items-center px-25 mb-10 tablet:mb-20">
          <div class="relative h-74 w-74 flex items-center justify-center bg-gray-200 rounded-full">
            <IconDocumentNotes class="icon-document-notes h-40" />
          </div>
          <p class="mt-20">
            Please fill in your shipping address
          </p>
        </div>
        <div v-else-if="modal.type === 'empty-transport'" class="flex flex-col items-center px-25 mb-10 tablet:mb-20">
          <div class="relative h-74 w-74 flex items-center justify-center bg-gray-200 rounded-full">
            <IconDocumentNotes class="icon-document-notes h-40" />
          </div>
          <p class="mt-20">
            {{ $t('order_body.no_transport_option_selected') }}

            {{ $t('order_body.no_transport_option_hint') }}
          </p>
        </div>
      </template>
      <template #footer>
        <div v-if="!orderSaved && modal.type !== 'empty-shipping' && modal.type !== 'empty-print'" class="flex items-center justify-between py-20 px-20 mobile-l:pb-15 mobile-l:pt-12
          touch-device:flex-row-reverse">
          <div class="flex -mx-5 touch-device:flex-row-reverse">
            <div class="px-5">
              <button disabled class="button button-primary button-lg button-filled" @click="modal.isShowing = false">
                {{ $t('generic_crud_labels.back') }}
              </button>
            </div>
            <div v-if="!modal.showNexButton && modal.type !== 'empty-transport'" class="px-5">
              <button disabled class="button button-secondary button-lg button-text" @click="successfulSubmit">
                {{ $t('generic_crud_labels.save') }}
              </button>
            </div>
            <div v-else-if="modal.showNexButton" class="px-5">
              <button disabled class="button button-secondary button-lg button-text" @click="showNextModal">
                {{ $t('generic_crud_labels.next') }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </UIModal>
    <form class="px-20 pb-10 bg-white rounded shadow-md print:pb-0 print:mt-1 print:shadow-none" @submit.prevent>
      <OrderHeaderDisabled v-model="order.header" :human-readable-id="humanReadableId" :location-name="location.name"
        :no-transitions="noTransitions" @images-loaded="headerImagesLoaded = true" :location="location"
        :tooltips="tooltips" />

      <OrderMetalComponentsDisabled v-model="order.metalComponents" class="mt-10"
        @images-loaded="metalComponentImagesLoaded = true" :location-id="location.id" />

      <OrderMoreOptionsDisabled v-model="order.moreOptions" :tooltips="tooltips" :location-name="location.name"
        class="mt-10" :location="location" :location-id="location.id" />

      <div v-if="location.id === 'oberndorf'" class="font-bold text-xs mt-5 ml-10">GS-Standard (wenn vom Kunden nichts
        vorgegeben ist). *FVZ nach EN1461, mit den hier definierten Zink-Schickstärken +
        DASt Vertrauenszone 1 (Sichtkontrolle) * Duroplex + Thermoplex: Standard 60μ Gr. + 60μ DK., C3 *
        Mindestrechnungsbetrag:
        30 EUR -> Feuerverzinkung</div>
      <OrderFooterDisabled :location-name="location.name" class="my-10" />
    </form>
    <p v-if="modifiedOrder" class="bottom-0 right-0 text-right hidden print:block absolute">
      {{
        modifiedOrder.toLocaleDateString('de-CH', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
      }}
    </p>
    <p v-else-if="createdOrder" class="bottom-0 right-0 text-right hidden print:block absolute">
      {{
        createdOrder.toLocaleDateString('de-CH', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }) || new Date().toLocaleDateString('de-CH', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
      }}
    </p>
    <p v-else class="bottom-0 right-0 text-right hidden print:block absolute">
      {{
        new Date().toLocaleDateString('de-CH', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        })
      }}
    </p>
  </div>
</template>

<script>
import { v4 as UUID } from 'uuid';
import printWindow from '@/helpers/functions/printWindow';
import UIModal from '@/components/UI/UIModal';
import IconClose from '@/components/icon/IconClose';
import IconDocumentNotes from '@/components/icon/IconDocumentNotes';
import IconPrint from '@/components/icon/IconPrint';
import {
  minMetalComponentRows,
  maxProfilesPerMetalComponent,
  defaultProfileTypeWithDimensions,
  defaultMetalComponent,
} from '@/helpers/metalComponent';
import OrderHeaderDisabled from './OrderHeaderDisabled';
import OrderMetalComponentsDisabled from './OrderMetalComponentsDisabled';
import OrderMoreOptionsDisabled from './OrderMoreOptionsDisabled';
import OrderFooterDisabled from './OrderFooterDisabled';

const defaultLocation = {
  id: 'wellhausen',
  name: 'Wellhausen',
};

export default {
  props: {
    createdOrder: {
      type: [String, Date],
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    modifiedOrder: {
      type: [String, Date],
      default: '',
    },
    existingOrder: {
      type: Object,
      default: null,
    },
    userDetails: {
      type: Object,
      default: null,
    },
    humanReadableId: {
      type: String,
      default: '',
    },
    location: {
      type: Object,
      default: () => ({
        id: defaultLocation.id,
        name: defaultLocation.name,
      }),
    },
    noTransitions: {
      type: Boolean,
      default: false,
    },
    tooltips: {
      type: Object,
      default: null,
    },
    orderSaved: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UIModal,
    IconClose,
    IconDocumentNotes,
    IconPrint,
    OrderHeaderDisabled,
    OrderMetalComponentsDisabled,
    OrderMoreOptionsDisabled,
    OrderFooterDisabled,
  },
  data: () => ({
    modal: {
      isShowing: false,
      showNexButton: false,
      type: 'no-treatment',
      isLoading: false,
    },
    headerImagesLoaded: false,
    metalComponentImagesLoaded: false,
    order: {
      header: {
        companyName: '',
        billingAddress: {
          city: '',
          country: 'CH',
          name: '',
          street: '',
          zipCode: '',
        },
        shippingAddress: {
          city: '',
          country: 'CH',
          name: '',
          street: '',
          zipCode: '',
        },
        phoneNumberForQuestions: '',
        offerNumber: '',
        deliveryDate: '',
        pickupDate: '',
        orderDate: new Date(),
        orderNumber: '',
        commission: '',
        deliverySlipNumber: '',
      },
      metalComponents: Array(minMetalComponentRows)
        .fill(0)
        .map(() => ({
          id: UUID(),
          clearRowIsVisible: false,
          ...defaultMetalComponent,
          profilesWithDimensions: [{ ...defaultProfileTypeWithDimensions }],
        })),
      moreOptions: {
        orderComments: '',
        treatment: {
          hotDipGalvanizingISO1461: false,
          DASt022TrustZone: [], // Possible values: 1, 2, 3
          onlyPickleZincAndBackBlack: false,
          duroplexCoating: false,
          thermoplexCoating: false,
          duroThermoShade: '',
          SPlusZ: false,
          ferrostyleCoatingN: false,
          ferrostyleCoatingP: false,
          ferrostyleShade: '',
          indoorApplication: false,
          outdoorApplication: false,
          galvanicZincPlating: false,
          brightChromePlating: false,
          blueChromating: false,
          EN1090: false,
          corrosivityCategory: [], // Possible values: C2, C3, C4, C5
          preparationDegree: [], // Possible values: P1, P2, P3
        },
        special: {
          cleanThread: false,
          cleanThreadCount: '',
          cleanThreadIn: false,
          cleanThreadOut: false,
          QS: false,
          measurementProtocol: false,
          measurementProtocolAdvanced: false,
          drilledInternalHoles: false,
          closeHoles: false,
          closeHolesCount: '',
          noAdditionallyDrilledHoles: false,
          removeZincOnly: false,
          burnOffPaintOnly: false,
          removePaintAndZinc: false,
          euroPallets: false,
          euroPalletsCount: '',
          palletFrames: false,
          palletFramesCount: '',
          halfPalletFrames: false,
          halfPalletFramesCount: '',
          disposablePallets: false,
          disposablePalletsCount: '',
          other: false,
          otherDescription: '',
        },
        transportDelivery: {
          byGalvaswissLorry: false,
          pickUpByHimself: false,
        },
        transportReturnDelivery: {
          byGalvaswissLorry: false,
          pickUpByHimself: false,
        },
      },
    },
    isEmptyShipping: false,
  }),
  created() {
    if (!this.existingOrder) return;
    let { metalComponents } = this.order;
    if (this.existingOrder?.metalComponents?.length) {
      metalComponents = []; let stopAtNumberOfComponents = minMetalComponentRows;

      if (this.existingOrder?.metalComponents?.length > minMetalComponentRows) {
        stopAtNumberOfComponents = this.existingOrder?.metalComponents?.length;
      }

      for (let i = 0; i < stopAtNumberOfComponents; i += 1) {
        if (this.existingOrder?.metalComponents[i]) {
          const { profilesWithDimensions, ...metalComponent } = this.existingOrder.metalComponents[i];
          let UIProfilesWithDimensions = [];

          if (profilesWithDimensions?.length) UIProfilesWithDimensions = [...profilesWithDimensions];

          if (UIProfilesWithDimensions?.length < maxProfilesPerMetalComponent) {
            UIProfilesWithDimensions.push({ ...defaultProfileTypeWithDimensions });
          }

          metalComponents.push({
            id: UUID(),
            clearRowIsVisible: false,
            ...defaultMetalComponent,
            ...metalComponent,
            profilesWithDimensions: UIProfilesWithDimensions,
          });
        } else {
          metalComponents.push(this.order.metalComponents[i]);
        }
      }
    }
    // debugger;
    if (this.existingOrder.header.deliveryDate) {
      this.existingOrder.header.deliveryDate = this.existingOrder.header.deliveryDate.toDate();
    }
    if (this.existingOrder.header.pickupDate) {
      this.existingOrder.header.pickupDate = this.existingOrder.header.pickupDate.toDate();
    }
    this.order = {
      header: {
        ...this.order.header,
        ...this.existingOrder.header,
      },
      metalComponents,
      moreOptions: {
        treatment: {
          ...this.order.moreOptions.treatment,
          ...this.existingOrder.treatment,
        },
        special: {
          ...this.order.moreOptions.special,
          ...this.existingOrder.special,
        },
        transportDelivery: {
          ...this.order.moreOptions.transportDelivery,
          ...this.existingOrder.transportDelivery,
        },
        transportReturnDelivery: {
          ...this.order.moreOptions.transportReturnDelivery,
          ...this.existingOrder.transportReturnDelivery,
        },
        orderComments: this.order.moreOptions.orderComments || this.existingOrder.orderComments,
      },
    };
    if (this.$route?.params?.duplicate) {
      this.order.header.orderDate = new Date();
    }
    // console.log(this.order);
  },
  updated() {
    if (this.userDetails) {
      this.order.header.billingAddress = this.userDetails.billingAddress;
      this.order.header.shippingAddress = this.userDetails.shippingAddress;
      if (this.userDetails.companyName) this.order.header.companyName = this.userDetails.companyName;
    }
  },
  watch: {
    imagesLoaded: {
      immediate: true,
      handler(value) {
        if (value) this.$emit('images-loaded');
      },
    },
    orderSaved: {
      handler(value) {
        // if (value) this.modal.isShowing = true;
        if (value && this.$route.name === 'OrderAdd') {
          this.$router.replace({
            name: 'OrderEdit',
            params: {
              locale: this.$i18n.locale,
              id: this.id,
              update: true,
            },
          });
        }
      },
    },
  },
  computed: {
    driverLine() {
      if (this.location.name === 'Oberndorf') {
        return 'Fahrer'
      } else {
        return 'Chauffeur'
      }
    },
    modalTitle() {
      if (this.isEmptyShipping) return 'Empty shipping address';
      if (this.orderSaved) return this.$t('order_body.print_order');
      if (this.modal.type === 'no-treatment') return this.$t('order_body.no_treatment_available');
      if (this.modal.type === 'empty-print') return this.$t('order_body.print_order');
      if (this.modal.type === 'empty-transport') return this.$t('order_body.transport_option');
      return 'Title';
    },
    imagesLoaded() {
      return this.headerImagesLoaded
        && (!this.order.metalComponents
          .flatMap((m) => m.profilesWithDimensions.filter((p) => p.type || p.dimensions))
          .length || (this.order.metalComponents && this.metalComponentImagesLoaded));
    },
  },
  methods: {
    submitForm() {
      this.isEmptyShipping = false;
      const { treatment: treatmentOptions } = this.order.moreOptions;

      if (this.$route.name !== 'OrderAdd') {
        if (Object.values(this.order.header.shippingAddress).includes('')) {
          this.isEmptyShipping = true;
          this.modal.type = 'empty-shipping';
          this.modal.showNexButton = false;
          this.modal.isShowing = true;
          return;
        }
        if (!(this.order.moreOptions.transportReturnDelivery.pickUpByHimself || this.order.moreOptions.transportReturnDelivery.byGalvaswissLorry) || !(this.order.moreOptions.transportDelivery.pickUpByHimself || this.order.moreOptions.transportDelivery.byGalvaswissLorry)) {
          this.modal.type = 'empty-transport';
          this.modal.showNexButton = false;
          this.modal.isShowing = true;
          return;
        }
        // No treatment is selected
        if (!treatmentOptions.hotDipGalvanizingISO1461
          && !treatmentOptions.onlyPickleZincAndBackBlack
          && !treatmentOptions.duroplexCoating
          && !treatmentOptions.thermoplexCoating
          && !treatmentOptions.SPlusZ
          && !treatmentOptions.ferrostyleCoatingN
          && !treatmentOptions.ferrostyleCoatingP
          && !treatmentOptions.galvanicZincPlating
          && !treatmentOptions.EN1090) {
          this.modal.type = 'no-treatment';
          this.modal.showNexButton = false;
          this.modal.isShowing = true;
          return;
        }
      }
      this.successfulSubmit();
    },
    submitPrintForm() {
      const { treatment: treatmentOptions } = this.order.moreOptions;

      if (this.$route.name !== 'OrderAdd') {
        if (Object.values(this.order.header.shippingAddress).includes('')) {
          this.isEmptyShipping = true;
          this.modal.type = 'empty-shipping';
          this.modal.showNexButton = false;
          this.modal.isShowing = true;
          return;
        }
        if (!(this.order.moreOptions.transportReturnDelivery.pickUpByHimself || this.order.moreOptions.transportReturnDelivery.byGalvaswissLorry) || !(this.order.moreOptions.transportDelivery.pickUpByHimself || this.order.moreOptions.transportDelivery.byGalvaswissLorry)) {
          this.modal.type = 'empty-transport';
          this.modal.showNexButton = false;
          this.modal.isShowing = true;
          return;
        }

        // No treatment is selected
        if (!treatmentOptions.hotDipGalvanizingISO1461
          && !treatmentOptions.onlyPickleZincAndBackBlack
          && !treatmentOptions.duroplexCoating
          && !treatmentOptions.thermoplexCoating
          && !treatmentOptions.SPlusZ
          && !treatmentOptions.ferrostyleCoatingN
          && !treatmentOptions.ferrostyleCoatingP
          && !treatmentOptions.galvanicZincPlating
          && !treatmentOptions.EN1090) {
          this.modal.type = 'no-treatment';
          this.modal.showNexButton = true;
          this.modal.isShowing = true;
          return;
        }
      }
      this.modal.type = 'empty-print';
      this.modal.showNexButton = false;
      this.modal.isShowing = true;
    },
    showNextModal() {
      this.modal.type = 'empty-print';
    },
    successfulSubmit() {
      if (this.existingOrder) {
        this.$emit('update', this.order);
        return;
      }
      this.$emit('add', this.order);
    },
    printWindow() {
      printWindow();
    },
    successfulSubmitPrint() {
      // console.log(this.order);
      this.$emit('updatePrint', this.order);
      this.modal.showNexButton = false;
      this.modal.isShowing = false;
    },
  },
};
</script>

<style scoped>
.icon-document-notes {
  & .primary {
    @apply fill-secondary;
  }

  & .secondary {
    @apply fill-primary;
  }
}

.icon-print .primary {
  @apply fill-white;
}
</style>
