<template>
  <div class="w-full inline-flex">
    <div class="w-35/100 border">
      <h2 class="form-label-primary text-sm py-2 border-b">{{ $t('order_more_options.treating') }}</h2>
      <div class="px-5 my-10">
        <div class="flex items-center justify-between mb-5" :class="{
          'print:hidden': !moreOptions.treatment.hotDipGalvanizingISO1461
            && !moreOptions.treatment.DASt022TrustZone.length
        }">
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.hotDipGalvanizingISO1461"
            input-classes="checkbox-input primary" class="form-checkbox form-checkbox-primary items-center" @change="moreOptions.treatment.hotDipGalvanizingISO1461
              ? showTooltip() : null">
            <span class="checkbox-label-primary inline-block">
              {{ $t('order_more_options.iso') }}
            </span>
          </UIInputCheckbox>
          <Transition name="transition-fade-slow">
            <div v-show="tooltipContentForHotDipGalvanizingISO1461" class="mr-10 print:hidden">
              <UITooltip :a11y="false" theme="galvaswiss" animation="shift-away" arrow arrow-type="round" distance="10"
                interactive trigger="click focus mouseenter" :max-width="400">
                <template #trigger>
                  <button type="button" class="h-22 w-22 align-middle select-none">
                    <IconInformation class="icon-information h-full w-full" />
                  </button>
                </template>
                <p v-html="tooltipContentForHotDipGalvanizingISO1461"></p>
              </UITooltip>
            </div>
          </Transition>
        </div>
        <div class="flex items-center text-xs pl-12 pr-7" :class="{
          'print:hidden': !moreOptions.treatment.hotDipGalvanizingISO1461
            && !moreOptions.treatment.DASt022TrustZone.length
        }">
          {{ $t('order_more_options.tolerance_zone') }}
          <div class="flex items-center select-none">
            <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.DASt022TrustZone" val="1"
              input-classes="checkbox-input secondary" class="form-checkbox form-checkbox-secondary items-center">
              <span class="checkbox-label-tertiary">1</span>
            </UIInputCheckbox>
            /
            <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.DASt022TrustZone" val="2"
              input-classes="checkbox-input secondary" class="form-checkbox form-checkbox-secondary items-center">
              <span class="checkbox-label-tertiary">2</span>
            </UIInputCheckbox>
            /
            <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.DASt022TrustZone" val="3"
              input-classes="checkbox-input secondary" class="form-checkbox form-checkbox-secondary items-center">
              <span class="checkbox-label-tertiary">3</span>
            </UIInputCheckbox>
          </div>
        </div>
        <div v-if="locationId !== 'aarberg'" class="border-separator" :class="{
          'print:hidden': !moreOptions.treatment.hotDipGalvanizingISO1461
            && !moreOptions.treatment.DASt022TrustZone.length
        }"></div>
        <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.onlyPickleZincAndBackBlack"
          v-if="locationId !== 'aarberg'" input-classes="checkbox-input primary"
          class="form-checkbox form-checkbox-primary items-center"
          :class="{ 'print:hidden': !moreOptions.treatment.onlyPickleZincAndBackBlack }">
          <span class="checkbox-label-primary">{{ $t('order_more_options.only_zync') }}</span>
        </UIInputCheckbox>
        <div class="border-separator" :class="{ 'print:hidden': !moreOptions.treatment.onlyPickleZincAndBackBlack }">
        </div>
        <div class="flex items-center justify-between"
          :class="{ 'print:hidden': !moreOptions.treatment.duroplexCoating }">
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.duroplexCoating"
            input-classes="checkbox-input primary" class="form-checkbox form-checkbox-primary items-center" @change="moreOptions.treatment.duroplexCoating && !moreOptions.treatment.duroThermoShade
              ? showTooltip('showDuroThermoShadeTooltip') : null">
            <span class="checkbox-label-primary inline-block">
              {{ $t('order_more_options.duroplex') }}
              <br class="laptop:hidden">
              {{ $t('order_more_options.fvz') }}
            </span>
          </UIInputCheckbox>
          <Transition name="transition-fade-slow">
            <div v-show="tooltipContentForDuroplexCoating" class="mr-10 print:hidden">
              <UITooltip :a11y="false" theme="galvaswiss" animation="shift-away" arrow arrow-type="round" distance="10"
                placement="right" interactive trigger="click focus mouseenter" :max-width="400">
                <template #trigger>
                  <button type="button" class="h-22 w-22 align-middle select-none">
                    <IconInformation class="icon-information h-full w-full" />
                  </button>
                </template>
                <p v-html="tooltipContentForDuroplexCoating"></p>
              </UITooltip>
            </div>
          </Transition>
        </div>
        <div class="flex items-center justify-between"
          :class="{ 'print:hidden': !moreOptions.treatment.thermoplexCoating }">
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.thermoplexCoating"
            input-classes="checkbox-input primary" class="form-checkbox form-checkbox-primary items-center" @change="moreOptions.treatment.thermoplexCoating && !moreOptions.treatment.duroThermoShade
              ? showTooltip('showDuroThermoShadeTooltip') : null">
            <span class="checkbox-label-primary inline-block">
              {{ $t('order_more_options.thermoplex') }}
              <br class="laptop:hidden">
              {{ $t('order_more_options.fvz_powder') }}
            </span>
          </UIInputCheckbox>
          <Transition name="transition-fade-slow">
            <div v-show="tooltipContentForThermoplexCoating" class="mr-10 print:hidden">
              <UITooltip :a11y="false" theme="galvaswiss" animation="shift-away" arrow arrow-type="round" distance="10"
                placement="right" interactive trigger="click focus mouseenter" :max-width="400">
                <template #trigger>
                  <button type="button" class="h-22 w-22 align-middle select-none">
                    <IconInformation class="icon-information h-full w-full" />
                  </button>
                </template>
                <p v-html="tooltipContentForThermoplexCoating"></p>
              </UITooltip>
            </div>
          </Transition>
        </div>
        <UITooltip :visible="showDuroThermoShadeTooltip" to="duro-thermo-shade" theme="galvaswiss" animation="shift-away"
          arrow arrow-type="round" distance="10" placement="top" trigger="manual" class="hidden"
          @hidden="showDuroThermoShadeTooltip = false">
          <h2 class="text-lg text-left mb-7">{{ $t('order_more_options.select_color_tone') }}</h2>
          {{ $t('order_more_options.provide_color_tone') }}
        </UITooltip>
        <label name="duro-thermo-shade" class="flex items-end pl-12 mt-2 select-none cursor-pointer" :class="{
          'print:hidden': !moreOptions.treatment.duroplexCoating && !moreOptions.treatment.thermoplexCoating,
          'shadow-danger-outline bg-gray-50 rounded': !showDuroThermoShadeTooltip
            && !moreOptions.treatment.duroThermoShade && !duroThermoShadeIsFocused
            && (moreOptions.treatment.duroplexCoating || moreOptions.treatment.thermoplexCoating)
        }">
          <span class="flex-shrink-0 text-xs">{{ ralLine }}:</span>
          <input disabled v-model="moreOptions.treatment.duroThermoShade" type="text"
            class="input-text min-w-0 text-sm ml-5 mr-10 border-b" @focusin="duroThermoShadeIsFocused = true"
            @focusout="duroThermoShadeIsFocused = false" @keydown.enter.prevent>
        </label>
        <div class="border-separator" :class="{
          'print:hidden': !moreOptions.treatment.duroplexCoating && !moreOptions.treatment.thermoplexCoating
        }"></div>
        <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.SPlusZ" input-classes="checkbox-input primary"
          class="form-checkbox form-checkbox-primary items-center"
          :class="{ 'print:hidden': !moreOptions.treatment.SPlusZ }">
          <span class="checkbox-label-primary">{{ $t('order_more_options.sz') }}</span>
        </UIInputCheckbox>
        <div class="border-separator" :class="{ 'print:hidden': !moreOptions.treatment.SPlusZ }"></div>
        <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.ferrostyleCoatingN"
          input-classes="checkbox-input primary" class="form-checkbox form-checkbox-primary items-center" :class="{
            'print:hidden': !moreOptions.treatment.ferrostyleCoatingN && !moreOptions.treatment.ferrostyleShade
              && !moreOptions.treatment.indoorApplication && !moreOptions.treatment.outdoorApplication
          }" @change="moreOptions.treatment.ferrostyleCoatingN && !moreOptions.treatment.ferrostyleShade
  ? showTooltip('showFerrostyleShadeTooltip') : null">
          <span class="checkbox-label-primary">{{ $t('order_more_options.ferro') }}</span>
        </UIInputCheckbox>
        <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.ferrostyleCoatingP"
          input-classes="checkbox-input primary" class="form-checkbox form-checkbox-primary items-center" :class="{
            'print:hidden': !moreOptions.treatment.ferrostyleCoatingP && !moreOptions.treatment.ferrostyleShade
              && !moreOptions.treatment.indoorApplication && !moreOptions.treatment.outdoorApplication
          }" @change="moreOptions.treatment.ferrostyleCoatingP && !moreOptions.treatment.ferrostyleShade
  ? showTooltip('showFerrostyleShadeTooltip') : null">
          <span class="checkbox-label-primary">{{ $t('order_more_options.ferrow_only_powder') }}</span>
        </UIInputCheckbox>
        <UITooltip :visible="showFerrostyleShadeTooltip" to="ferrostyle-shade" theme="galvaswiss" animation="shift-away"
          arrow arrow-type="round" distance="10" placement="top" trigger="manual" class="hidden"
          @hidden="showFerrostyleShadeTooltip = false">
          <h2 class="text-lg text-left mb-7">{{ $t('order_more_options.select_color_tone_and_application') }}</h2>
          {{ $t('order_more_options.please_provide_color_tone_and_application') }}
        </UITooltip>
        <div name="ferrostyle-shade" :class="{
          'shadow-danger-outline bg-gray-50 rounded': !showFerrostyleShadeTooltip && !ferrostyleShadeIsFocused
            && (moreOptions.treatment.ferrostyleCoatingN || moreOptions.treatment.ferrostyleCoatingP)
            && !(moreOptions.treatment.ferrostyleShade
              && (moreOptions.treatment.indoorApplication || moreOptions.treatment.outdoorApplication))
        }">
          <label class="flex items-end pl-12 mt-5 select-none cursor-pointer" :class="{
            'print:hidden': !moreOptions.treatment.ferrostyleShade && !moreOptions.treatment.ferrostyleShade
              && !moreOptions.treatment.ferrostyleCoatingP && !moreOptions.treatment.indoorApplication
              && !moreOptions.treatment.outdoorApplication,
          }">
            <span class="flex-shrink-0 text-xs">{{ ralLine }}:</span>
            <input disabled v-model="moreOptions.treatment.ferrostyleShade" type="text"
              class="input-text min-w-0 text-sm ml-5 mr-10 border-b" @focusin="ferrostyleShadeIsFocused = true"
              @focusout="ferrostyleShadeIsFocused = false" @keydown.enter.prevent>
          </label>
          <div class="inline-flex flex-col items-start mt-1" :class="{
            'print:hidden': !moreOptions.treatment.ferrostyleShade && !moreOptions.treatment.ferrostyleCoatingN
              && !moreOptions.treatment.ferrostyleCoatingP && !moreOptions.treatment.indoorApplication
              && !moreOptions.treatment.outdoorApplication
          }">
            <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.indoorApplication"
              input-classes="checkbox-input secondary" class="form-checkbox form-checkbox-primary items-center" :class="{
                'print:hidden': !moreOptions.treatment.ferrostyleShade && !moreOptions.treatment.ferrostyleCoatingN
                  && !moreOptions.treatment.ferrostyleCoatingP && !moreOptions.treatment.indoorApplication
                  && !moreOptions.treatment.outdoorApplication
              }">
              <span class="checkbox-label-secondary">
                {{ $t('order_more_options.inner_use') }}
                <br class="laptop:hidden">
                <span class="font-bold italic">
                  {{ $t('order_more_options.you_must_select') }}
                </span>
              </span>
            </UIInputCheckbox>
            <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.outdoorApplication"
              input-classes="checkbox-input secondary" class="form-checkbox form-checkbox-primary items-center" :class="{
                'print:hidden': !moreOptions.treatment.ferrostyleShade && !moreOptions.treatment.ferrostyleCoatingN
                  && !moreOptions.treatment.ferrostyleCoatingP && !moreOptions.treatment.indoorApplication
                  && !moreOptions.treatment.outdoorApplication
              }">
              <span class="checkbox-label-secondary">
                {{ $t('order_more_options.outside_use') }}
                <br class="laptop:hidden">
                <span class="font-bold italic">
                  {{ $t('order_more_options.you_must_select') }}
                </span>
              </span>
            </UIInputCheckbox>
          </div>
        </div>
        <div class="border-separator" :class="{
          'print:hidden': !moreOptions.treatment.ferrostyleShade && !moreOptions.treatment.ferrostyleCoatingN
            && !moreOptions.treatment.ferrostyleCoatingP && !moreOptions.treatment.indoorApplication
            && !moreOptions.treatment.outdoorApplication
        }"></div>
        <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.galvanicZincPlating"
          input-classes="checkbox-input primary mt-5" class="form-checkbox form-checkbox-primary" :class="{
            'print:hidden': !moreOptions.treatment.galvanicZincPlating && !moreOptions.treatment.brightChromePlating
              && !moreOptions.treatment.blueChromating
          }">
          <span class="checkbox-label-primary inline-flex flex-col">
            {{ $t('order_more_options.galva') }}
            <span v-if="locationId !== 'aarberg'" class="font-normal text-xs leading-tight">{{
              (location.id !== 'oberndorf') ? $t('order_more_options.galva_duration') : ''
            }}</span>
          </span>
        </UIInputCheckbox>
        <div class="inline-flex flex-col items-start" v-if="locationId !== 'aarberg'">
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.brightChromePlating"
            input-classes="checkbox-input secondary" class="form-checkbox form-checkbox-primary items-center" :class="{
              'print:hidden': !moreOptions.treatment.galvanicZincPlating && !moreOptions.treatment.brightChromePlating
            }">
            <span class="checkbox-label-secondary">
              {{ $t('order_more_options.glanz') }}
            </span>
          </UIInputCheckbox>
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.blueChromating"
            input-classes="checkbox-input secondary" class="form-checkbox form-checkbox-primary items-center" :class="{
              'print:hidden': !moreOptions.treatment.galvanicZincPlating && !moreOptions.treatment.blueChromating
            }">
            <span class="checkbox-label-secondary">
              {{ $t('order_more_options.blue') }}
            </span>
          </UIInputCheckbox>
        </div>
        <div class="border-separator" :class="{
          'print:hidden': !moreOptions.treatment.galvanicZincPlating && !moreOptions.treatment.brightChromePlating
            && !moreOptions.treatment.blueChromating
        }"></div>
        <UITooltip :visible="showEn100Tooltip" to="en-100" theme="galvaswiss" animation="shift-away" arrow
          arrow-type="round" distance="10" placement="top" trigger="manual" class="hidden"
          @hidden="showEn100Tooltip = false">
          <h2 class="text-lg text-left mb-7">{{ $t('order_more_options.en1090') }}</h2>
          {{ $t('order_more_options.fill_en1090') }}
        </UITooltip>
        <div name="en-100" class="flex justify-between">
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.EN1090"
            input-classes="checkbox-input primary" class="form-checkbox form-checkbox-primary items-center" :class="{
              'print:hidden': !moreOptions.treatment.EN1090 && !moreOptions.treatment.corrosivityCategory.length
                && !moreOptions.treatment.preparationDegree.length
            }">
            <span class="checkbox-label-primary">{{ $t('order_more_options.en1090') }}</span>
          </UIInputCheckbox>
          <Transition name="transition-fade-slow">
            <div v-show="tooltipContentForPreparationDegreeTest" class="mr-10 print:hidden">
              <UITooltip :a11y="false" theme="galvaswiss" animation="shift-away" arrow arrow-type="round" distance="10"
                interactive trigger="click focus mouseenter" :max-width="400">
                <template #trigger>
                  <button type="button" class="h-22 w-22 align-top select-none hidden">
                    <IconInformation class="icon-information h-full w-full" />
                  </button>
                </template>
                <p v-html="tooltipContentForPreparationDegreeTest"></p>
              </UITooltip>
            </div>
          </Transition>
        </div>
        <div class="flex items-center text-xs pl-12" :class="{
          'print:hidden': !moreOptions.treatment.EN1090 && !moreOptions.treatment.corrosivityCategory.length
        }">
          {{ $t('order_more_options.corrosion') }}
          <div class="flex items-center flex-wrap select-none">
            <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.corrosivityCategory" val="C2"
              input-classes="checkbox-input tertiary" class="form-checkbox form-checkbox-secondary items-center">
              <span class="checkbox-label-tertiary">C2</span>
            </UIInputCheckbox>
            /
            <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.corrosivityCategory" val="C3"
              input-classes="checkbox-input tertiary" class="form-checkbox form-checkbox-secondary items-center">
              <span class="checkbox-label-tertiary">C3</span>
            </UIInputCheckbox>
            /
            <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.corrosivityCategory" val="C4"
              input-classes="checkbox-input tertiary" class="form-checkbox form-checkbox-secondary items-center">
              <span class="checkbox-label-tertiary">C4</span>
            </UIInputCheckbox>
            /
            <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.corrosivityCategory" val="C5"
              input-classes="checkbox-input tertiary" class="form-checkbox form-checkbox-secondary items-center">
              <span class="checkbox-label-tertiary">C5</span>
            </UIInputCheckbox>
          </div>
        </div>
        <div class="flex items-center justify-between" :class="{
          'print:hidden': !moreOptions.treatment.EN1090 && !moreOptions.treatment.preparationDegree.length
        }">
          <div class="flex items-center text-xs pl-12">
            {{ $t('order_more_options.preparation_level') }}
            <div class="flex items-center flex-wrap ml-18 select-none">
              <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.preparationDegree" val="P1"
                input-classes="checkbox-input tertiary" class="form-checkbox form-checkbox-secondary items-center">
                <span class="checkbox-label-tertiary">P1</span>
              </UIInputCheckbox>
              /
              <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.preparationDegree" val="P2"
                input-classes="checkbox-input tertiary" class="form-checkbox form-checkbox-secondary items-center">
                <span class="checkbox-label-tertiary">P2</span>
              </UIInputCheckbox>
              /
              <UIInputCheckbox :is-disabled="true" v-model="moreOptions.treatment.preparationDegree" val="P3"
                input-classes="checkbox-input tertiary" class="form-checkbox form-checkbox-secondary items-center">
                <span class="checkbox-label-tertiary">P3</span>
              </UIInputCheckbox>
            </div>
          </div>
          <Transition name="transition-fade-slow">
            <div v-show="tooltipContentForPreparationDegree" class="mr-10 print:hidden">
              <UITooltip :a11y="false" theme="galvaswiss" animation="shift-away" arrow arrow-type="round" distance="10"
                interactive trigger="click focus mouseenter" :max-width="550">
                <template #trigger>
                  <button type="button" class="h-22 w-22 align-top select-none">
                    <IconInformation class="icon-information h-full w-full" />
                  </button>
                </template>
                <p v-html="tooltipContentForPreparationDegree"></p>
              </UITooltip>
            </div>
          </Transition>
        </div>
      </div>
    </div>
    <div class="w-31/100 border-t border-b">
      <h2 class="form-label-primary text-sm py-2 border-b">{{ $t('order_more_options.specials') }}</h2>
      <div class="w-full px-5 my-10">
        <div class="inline-flex flex-col items-start">
          <div class="flex mr-20"
            :class="{ 'print:hidden': !moreOptions.special.cleanThread || !moreOptions.special.cleanThreadCount }">
            <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.cleanThread"
              input-classes="checkbox-input secondary"
              class="form-checkbox form-checkbox-primary flex-shrink-0 items-center mb-3"
              @change="!moreOptions.special.cleanThread ? moreOptions.special.cleanThreadCount = '' : null"
              @click.native="!moreOptions.special.cleanThread ? $refs['input-clean-thread'].focus() : null">
              <span class="checkbox-label-secondary">
                {{ $t('order_more_options.clean') }}
              </span>
            </UIInputCheckbox>
            <label class="flex text-xs select-none cursor-pointer -ml-5">
              <input disabled v-model.number="moreOptions.special.cleanThreadCount" v-mask="'####'"
                ref="input-clean-thread" type="tel" class="input-text w-35 border-b"
                @change="moreOptions.special.cleanThread = moreOptions.special.cleanThreadCount > 0"
                @keydown.enter.prevent>
              <span class="flex-shrink-0 mt-2">
                {{ $t('order_more_options.pcs') }}
              </span>
            </label>
          </div>

          <div class="flex mr-20"
            :class="{ 'print:hidden': !moreOptions.special.cleanThread, 'hidden': !moreOptions.special.cleanThread }">
            <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.cleanThreadIn"
              input-classes="checkbox-input secondary"
              class="form-checkbox form-checkbox-primary flex-shrink-0 items-center mb-3"
              @change="!moreOptions.special.cleanThreadIn ? '' : null"
              @click.native="!moreOptions.special.cleanThreadIn ? $refs['input-clean-thread'].focus() : null">
              <span class="checkbox-label-secondary">
                {{ $t('order_more_options.clean_thread_in') }}
              </span>
            </UIInputCheckbox>
          </div>

          <div class="flex mr-20"
            :class="{ 'print:hidden': !moreOptions.special.cleanThread, 'hidden': !moreOptions.special.cleanThread }">
            <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.cleanThreadOut"
              input-classes="checkbox-input secondary"
              class="form-checkbox form-checkbox-primary flex-shrink-0 items-center mb-3"
              @change="!moreOptions.special.cleanThreadOut ? '' : null"
              @click.native="!moreOptions.special.cleanThreadOut ? $refs['input-clean-thread'].focus() : null">
              <span class="checkbox-label-secondary">
                {{ $t('order_more_options.clean_thread_out') }}
              </span>
            </UIInputCheckbox>
          </div>

          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.QS" input-classes="checkbox-input secondary"
            class="form-checkbox form-checkbox-primary items-center mb-3"
            :class="{ 'print:hidden': !moreOptions.special.QS }">
            <span class="checkbox-label-secondary">{{ $t('order_more_options.qs') }}</span>
          </UIInputCheckbox>
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.measurementProtocol"
            input-classes="checkbox-input secondary" class="form-checkbox form-checkbox-primary items-center mb-3"
            :class="{ 'print:hidden': !moreOptions.special.measurementProtocol }">
            <!-- <span class="checkbox-label-secondary">Messprotokoll (SFr. 50.-)</span> -->
            <span class="checkbox-label-secondary">{{ (location.id !== 'oberndorf') ?
              $t('order_more_options.measurment_log') : $t('order_more_options.measurment_log_de') }}</span>
          </UIInputCheckbox>
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.measurementProtocolAdvanced"
            input-classes="checkbox-input secondary" class="form-checkbox form-checkbox-primary items-center mb-3"
            :class="{ 'print:hidden': !moreOptions.special.measurementProtocolAdvanced }">
            <span class="checkbox-label-secondary">{{ (location.id !== 'oberndorf') ?
              $t('order_more_options.measurment_log_duro') : $t('order_more_options.measurment_log_duro_de') }}</span>
          </UIInputCheckbox>
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.drilledInternalHoles"
            input-classes="checkbox-input secondary" class="form-checkbox form-checkbox-primary items-center mb-3"
            :class="{ 'print:hidden': !moreOptions.special.drilledInternalHoles }">
            <span class="checkbox-label-secondary">{{ $t('order_more_options.holes') }}</span>
          </UIInputCheckbox>

          <div class="flex mr-20">
            <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.closeHoles"
              input-classes="checkbox-input secondary" class="form-checkbox form-checkbox-primary items-center mb-3"
              :class="{ 'print:hidden': !moreOptions.special.closeHoles }">
              <span class="checkbox-label-secondary">{{ $t('order_more_options.holes_closed') }}</span>
            </UIInputCheckbox>
            <label class="flex text-xs select-none cursor-pointer -ml-5">
              <input disabled v-model.number="moreOptions.special.closeHolesCount" v-mask="'####'" ref="input-close-holes"
                type="tel" class="input-text w-35 border-b"
                @change="moreOptions.special.closeHoles = moreOptions.special.closeHolesCount > 0" @keydown.enter.prevent>
              <span class="flex-shrink-0 mt-2">
                {{ $t('order_more_options.pcs') }}
              </span>
            </label>
          </div>

          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.noAdditionallyDrilledHoles"
            input-classes="checkbox-input secondary" class="form-checkbox form-checkbox-primary items-center mb-3"
            :class="{ 'print:hidden': !moreOptions.special.noAdditionallyDrilledHoles }">
            <span class="checkbox-label-secondary">{{ $t('order_more_options.no_holes') }}</span>
          </UIInputCheckbox>
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.removeZincOnly"
            input-classes="checkbox-input secondary" class="form-checkbox form-checkbox-primary items-center mb-3"
            :class="{ 'print:hidden': !moreOptions.special.removeZincOnly }">
            <span class="checkbox-label-secondary">
              {{ $t('order_more_options.remove_zync') }}
              <br class="laptop:hidden">
              {{ $t('order_more_options.more_cost') }}
            </span>
          </UIInputCheckbox>
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.burnOffPaintOnly"
            input-classes="checkbox-input secondary" class="form-checkbox form-checkbox-primary items-center mb-3"
            :class="{ 'print:hidden': !moreOptions.special.burnOffPaintOnly }">
            <span class="checkbox-label-secondary">
              {{ locationId === 'aarberg' ? $t('order_more_options.old_color_aarberg') : (locationId !== 'oberndorf' ?
                $t('order_more_options.old_color') : $t('order_more_options.old_color_de')) }}
              <br class="laptop:hidden">
              {{ $t('order_more_options.price_on_request') }}
            </span>
          </UIInputCheckbox>
          <p class="font-bold text-xs mt-5 ml-10" :class="{
            'print:hidden': !moreOptions.special.burnOffPaintOnly && !moreOptions.special.removePaintAndZinc
          }"> {{ $t('order_more_options.only_with_meeting') }} </p>
        </div>
      </div>
      <div class="px-5 mt-40">
        <h3 class="font-bold text-sm text-center mb-20" :class="{
          'print:hidden': !moreOptions.special.euroPallets && !moreOptions.special.palletFrames
            && !moreOptions.special.halfPalletFrames && !moreOptions.special.disposablePallets
            && !moreOptions.special.other
        }">{{ $t('order_more_options.pack') }}</h3>
        <div class="flex mb-10 mr-20"
          :class="{ 'print:hidden': !moreOptions.special.euroPalletsCount || !moreOptions.special.euroPalletsCount }">
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.euroPallets"
            input-classes="checkbox-input secondary"
            class="form-checkbox form-checkbox-primary flex-shrink-0 items-center"
            @change="!moreOptions.special.euroPallets ? moreOptions.special.euroPalletsCount = '' : null"
            @click.native="!moreOptions.special.euroPallets ? $refs['input-euro-pallets-count'].focus() : null">
            <span class="checkbox-label-secondary">{{ $t('order_more_options.eu_pallets') }}</span>
          </UIInputCheckbox>
          <label class="flex items-end select-none cursor-pointer -ml-7">
            <span class="flex-shrink-0 text-xs">{{ $t('order_more_options.quantaty_pallets') }}</span>
            <input disabled v-model.number="moreOptions.special.euroPalletsCount" v-mask="'#####'"
              ref="input-euro-pallets-count" type="tel" class="input-text w-full text-sm ml-10 border-b overflow-auto"
              @change="moreOptions.special.euroPallets = moreOptions.special.euroPalletsCount > 0" @keydown.enter.prevent>
          </label>
        </div>
        <div class="flex mb-10 mr-20"
          :class="{ 'print:hidden': !moreOptions.special.palletFrames || !moreOptions.special.palletFramesCount }">
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.palletFrames"
            input-classes="checkbox-input secondary"
            class="form-checkbox form-checkbox-primary flex-shrink-0 items-center"
            @change="!moreOptions.special.palletFrames ? moreOptions.special.palletFramesCount = '' : null"
            @click.native="!moreOptions.special.palletFrames ? $refs['input-pallet-count'].focus() : null">
            <span class="checkbox-label-secondary">{{ $t('order_more_options.pallet_frames') }}</span>
          </UIInputCheckbox>
          <label class="flex items-end select-none cursor-pointer -ml-7">
            <span class="flex-shrink-0 text-xs">{{ $t('order_more_options.quantaty_pallets') }}</span>
            <input disabled v-model.number="moreOptions.special.palletFramesCount" v-mask="'####'"
              ref="input-pallet-count" type="tel" class="input-text w-full text-sm ml-10 border-b overflow-auto"
              @change="moreOptions.special.palletFrames = moreOptions.special.palletFramesCount > 0"
              @keydown.enter.prevent>
          </label>
        </div>
        <div class="flex mb-10 mr-20" :class="{
          'print:hidden': !moreOptions.special.halfPalletFrames || !moreOptions.special.halfPalletFramesCount
        }">
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.halfPalletFrames"
            input-classes="checkbox-input secondary"
            class="form-checkbox form-checkbox-primary flex-shrink-0 items-center"
            @change="!moreOptions.special.halfPalletFrames ? moreOptions.special.halfPalletFramesCount = '' : null"
            @click.native="!moreOptions.special.halfPalletFrames ? $refs['input-half-pallet-count'].focus() : null">
            <span class="checkbox-label-secondary">{{ $t('order_more_options.half_pallete') }}</span>
          </UIInputCheckbox>
          <label class="flex items-end select-none cursor-pointer -ml-7">
            <span class="flex-shrink-0 text-xs">{{ $t('order_more_options.quantaty_pallets') }}</span>
            <input disabled v-model.number="moreOptions.special.halfPalletFramesCount" v-mask="'###'"
              ref="input-half-pallet-count" type="tel" class="input-text w-full text-sm ml-10 border-b overflow-auto"
              @change="moreOptions.special.halfPalletFrames = moreOptions.special.halfPalletFramesCount > 0"
              @keydown.enter.prevent>
          </label>
        </div>
        <div class="flex mb-10 mr-20" :class="{
          'print:hidden': !moreOptions.special.disposablePallets || !moreOptions.special.disposablePalletsCount
        }">
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.disposablePallets"
            input-classes="checkbox-input secondary"
            class="form-checkbox form-checkbox-primary flex-shrink-0 items-center"
            @change="!moreOptions.special.disposablePallets ? moreOptions.special.disposablePalletsCount = '' : null"
            @click.native="!moreOptions.special.disposablePallets ? $refs['input-disposable-count'].focus() : null">
            <span class="checkbox-label-secondary">{{ $t('order_more_options.one_way_pallete') }}</span>
          </UIInputCheckbox>
          <label class="flex items-end select-none cursor-pointer -ml-7">
            <span class="flex-shrink-0 text-xs">{{ $t('order_more_options.quantaty_pallets') }}</span>
            <input disabled v-model.number="moreOptions.special.disposablePalletsCount" v-mask="'####'"
              ref="input-disposable-count" type="tel" class="input-text w-full text-sm ml-10 border-b overflow-auto"
              @change="moreOptions.special.disposablePallets = moreOptions.special.disposablePalletsCount > 0"
              @keydown.enter.prevent>
          </label>
        </div>
        <div class="flex mr-20"
          :class="{ 'print:hidden': !moreOptions.special.other || !moreOptions.special.otherDescription }">
          <UIInputCheckbox :is-disabled="true" v-model="moreOptions.special.other"
            input-classes="checkbox-input secondary"
            class="form-checkbox form-checkbox-primary flex-shrink-0 items-center"
            @change="!moreOptions.special.other ? moreOptions.special.otherDescription = '' : null"
            @click.native="!moreOptions.special.other ? $refs['input-other-description'].focus() : null">
            <span class="checkbox-label-secondary">{{ $t('order_more_options.other') }}</span>
          </UIInputCheckbox>
          <label class="flex items-end select-none cursor-pointer -ml-7">
            <span class="flex-shrink-0 text-xs">:</span>
            <input disabled v-model="moreOptions.special.otherDescription" ref="input-other-description" type="text"
              class="input-text w-full text-sm ml-10 border-b overflow-auto"
              @change="moreOptions.special.other = !!moreOptions.special.otherDescription" @keydown.enter.prevent>
          </label>
        </div>
      </div>
    </div>
    <div class="w-34/100 border">
      <div class="flex">
        <div class="w-1/2 border-r">
          <h2 class="form-label-primary text-sm py-2 border-b">{{ $t('order_more_options.transport') }}</h2>
          <div class="pt-5 px-7">
            <div class="flex flex-col">
              <h3 class="text-xs text-center leading-tight p-5 border">
                {{ $t('order_more_options.deliver_to', { locationName }) }}
              </h3>
              <UIInputCheckbox :is-disabled="true" v-model="moreOptions.transportDelivery.byGalvaswissLorry"
                input-wrapper-classes="w-30 inline-block text-center pr-4" input-classes="checkbox-input tertiary mr-5"
                class="form-checkbox form-checkbox-tertiary border-b border-l border-r">
                <span class="checkbox-label-quaternary border-l">{{ $t('order_more_options.lorry_galva') }}</span>
              </UIInputCheckbox>
              <UIInputCheckbox :is-disabled="true" v-model="moreOptions.transportDelivery.pickUpByHimself"
                input-wrapper-classes="w-30 inline-block text-center pr-4" input-classes="checkbox-input tertiary mr-5"
                class="form-checkbox form-checkbox-tertiary border-b border-l border-r">
                <span class="checkbox-label-quaternary border-l">{{ $t('order_more_options.self') }}</span>
              </UIInputCheckbox>
            </div>
            <div class="flex flex-col mt-30 mb-5 print:mt-15">
              <h3 class="text-xs text-center leading-tight p-5 border">
                {{ $t('order_more_options.returns', { locationName }) }}
              </h3>
              <UIInputCheckbox :is-disabled="true" v-model="moreOptions.transportReturnDelivery.byGalvaswissLorry"
                input-wrapper-classes="w-30 inline-block text-center pr-4" input-classes="checkbox-input tertiary mr-5"
                class="form-checkbox form-checkbox-tertiary border-b border-l border-r">
                <span class="checkbox-label-quaternary border-l">{{ $t('order_more_options.lorry_galva') }}</span>
              </UIInputCheckbox>
              <UIInputCheckbox :is-disabled="true" v-model="moreOptions.transportReturnDelivery.pickUpByHimself"
                input-wrapper-classes="w-30 inline-block text-center pr-4" input-classes="checkbox-input tertiary mr-5"
                class="form-checkbox form-checkbox-tertiary border-b border-l border-r">
                <span class="checkbox-label-quaternary border-l">{{ $t('order_more_options.self') }}</span>
              </UIInputCheckbox>
            </div>
          </div>
        </div>
        <div class="w-1/2">
          <h2 class="form-label-primary text-sm py-2 border-b">{{ $t('order_more_options.terms_notes') }}</h2>
          <div class="p-5">
            <p class="text-xs leading-tight">
              {{ $t('order_more_options.terms') }}
              <a target="_blank" rel="noopener" :href="`https://www.galvaswiss.ch/${$i18n.locale}/downloads/agbs.html`"
                class="font-bold underline mb-5 hover:text-primary">{{ location.companyName }}</a>
            </p>
            <br />
            <h3 v-if="location.id === 'oberndorf'" class="text-xs">
              Ursprungserklärung
            </h3>
            <p v-if="location.id === 'oberndorf'" class="text-xs leading-tight">
              Der Unterzeichnete erklärt, dass die in diesem Auftrag
              aufgeführten Waren in der EU hergestellt worden sind und den Ursprungsregeln
              für den Präferenzverkehr mit der Schweiz entsprechen: Er verpflichtet sich, den
              Zollbehörden auf Verlangen Nachweise zu dieser Erklärung vorzulegen.
            </p>
            <p v-if="location.id === 'oberndorf'" class="text-xs leading-tight mt-10">
              Ort, Datum und Unterschrift
            </p>
            <p v-if="location.id === 'oberndorf'" class="text-xs leading-tight mt-10 mb-10">
              ___________________________
            </p>
          </div>
        </div>
      </div>
      <div class="w-full border-t border-b" style="height: 276px">
        <h2 class="form-label-primary text-sm py-2 border-b">{{ $t('order_more_options.comments') }}</h2>
        <UIInputTextArea v-model="moreOptions.orderComments" disabled="true" class="h-full w-full"></UIInputTextArea>
      </div>
    </div>
  </div>
</template>


<script>
import UIInputCheckbox from '@/components/UI/input/UIInputCheckbox';
import IconInformation from '@/components/icon/IconInformation';
import UIInputTextArea from '@/components/UI/input/UIInputTextArea';
import { defaultLocale } from '@/i18n';


export default {
  model: {
    prop: 'moreOptions',
    event: 'change',
  },
  props: {
    moreOptions: {
      type: Object,
      default: null,
    },
    tooltips: {
      type: Object,
      default: null,
    },
    locationName: {
      type: String,
      default: 'Wellhausen',
    },
    location: {
      type: Object,
      default: { companyName: '' },
    },
    locationId: {
      type: String,
      default: '',
    },
  },
  components: {
    UIInputCheckbox,
    IconInformation,
    UIInputTextArea
  },
  data: () => ({
    duroThermoShadeIsFocused: false,
    showDuroThermoShadeTooltip: false,
    showEn100Tooltip: false,
    ferrostyleShadeIsFocused: false,
    showFerrostyleShadeTooltip: false,
  }),
  computed: {
    ralLine() {
      if (this.locationId === 'oberndorf') {
        return 'Farbton'
      } else {
        return this.$t('order_more_options.ral')
      }
    },
    tooltipContentForHotDipGalvanizingISO1461() {
      return this.getTooltipContent('hotDipGalvanizingISO1461');
    },
    tooltipContentForDuroplexCoating() {
      return this.getTooltipContent('duroplexCoating');
    },
    tooltipContentForThermoplexCoating() {
      return this.getTooltipContent('thermoplexCoating');
    },
    tooltipContentForPreparationDegree() {
      return this.getTooltipContent('preparationDegree');
    },
    tooltipContentForPreparationDegreeTest() {
      return this.getTooltipContent('preparationDegreeTest');
    },

  },
  methods: {
    getTooltipContent(tooltipID) {
      // Tooltip is not available
      if (!this.tooltips || !this.tooltips[tooltipID]) return '';

      // Tooltip doesn't have the current locale
      if (!this.tooltips[tooltipID][this.$i18n.locale]) {
        // Default de locale is not available
        if (!this.tooltips[tooltipID][defaultLocale]) return '';
        return this.tooltips[tooltipID][defaultLocale];
      }
      // if (tooltipID == 'duroplexCoating') {
      //   debugger;
      //   console.log(this.tooltips[tooltipID]['en']);
      //   console.log(this.tooltips[tooltipID]['en'].indexOf("•"));
      //   debugger;
      //   // Default de locale is not available
      //   return this.tooltips[tooltipID]['en'];
      // }

      return this.tooltips[tooltipID][this.$i18n.locale];
    },
    showTooltip(tooltipName) {
      this[tooltipName] = true;
      this.showEn100Tooltip = true;
    },
  },
};
</script>


<style>
.icon-information {
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.75));

  & .primary {
    @apply fill-primary;
  }

  & .secondary {
    @apply fill-white;
  }
}

.border-separator {
  @apply mx-5 my-7 border-b;
}

.tippy-content a {
  color: #e3360a;
  font-weight: 700;
}
</style>
