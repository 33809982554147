import { FBStorageRef } from '@/helpers/firebaseServices';

// eslint-disable-next-line import/prefer-default-export
export function orderToFormDataStructure(order, downloadImages = false) {
  const formData = {};

  formData.header = {
    ...(order.companyName && { companyName: order.companyName }),
    ...(order.billingAddress && { billingAddress: order.billingAddress }),
    ...(order.shippingAddress && { shippingAddress: order.shippingAddress }),
    ...(order.phoneNumberForQuestions && {
      phoneNumberForQuestions: order.phoneNumberForQuestions,
    }),
    ...(order.offerNumber && { offerNumber: order.offerNumber }),
    ...(order.deliveryDate && { deliveryDate: order.deliveryDate }),
    ...(order.pickupDate && { pickupDate: order.pickupDate }),
    ...(order.orderNumber && { orderNumber: order.orderNumber }),
    ...(order.commission && { commission: order.commission }),
    ...(order.deliverySlipNumber && { deliverySlipNumber: order.deliverySlipNumber }),
    ...(order.createdTime && { createdTime: order.createdTime }),
  };
  if (!Object.keys(formData.header).length) delete formData.header;

  // Metal components
  const imageDownloads = [];

  if (order.metalComponents && order.metalComponents.length) {
    const cleanMetalComponents = [];

    order.metalComponents.forEach((metalComponent, componentIndex) => {
      // Metal component - Profile types
      const profilesWithDimensions = [];
      if (metalComponent?.profilesWithDimensions?.length) {
        metalComponent.profilesWithDimensions.forEach((profileWithDimensions) => {
          if (profileWithDimensions.type || profileWithDimensions.dimensions) {
            profilesWithDimensions.push({ ...profileWithDimensions });
          }
        });
      }

      // Metal component
      const cleanMetalComponent = {
        ...(metalComponent.position && { position: metalComponent.position }),
        ...(metalComponent.count && { count: metalComponent.count }),
        ...(metalComponent.materialDesignation && {
          materialDesignation: metalComponent.materialDesignation,
        }),
        ...(metalComponent.imageStoragePath && {
          imageStoragePath: metalComponent.imageStoragePath,
        }),
        ...(profilesWithDimensions.length && { profilesWithDimensions }),
        ...(metalComponent.length && { length: metalComponent.length }),
        ...(metalComponent.width && { width: metalComponent.width }),
        ...(metalComponent.depth && { depth: metalComponent.depth }),
      };
      // Get Image download URLs
      if (downloadImages && metalComponent.imageStoragePath) {
        const imageDownload = FBStorageRef.child(metalComponent.imageStoragePath)
          .getDownloadURL()
          .then((imageURL) => {
            formData.metalComponents[componentIndex].imageURL = imageURL;
          })
          .catch((error) => {
            console.error(error);
          });
        imageDownloads.push(imageDownload);
      }

      if (Object.keys(cleanMetalComponent).length) cleanMetalComponents.push(cleanMetalComponent);
    });

    if (cleanMetalComponents.length) formData.metalComponents = cleanMetalComponents;
  }

  // More options
  // More options - Treatment
  if (order.treatment && Object.keys(order.treatment).length) {
    const { treatment: treatmentOptions } = order;

    formData.treatment = {
      ...(treatmentOptions.hotDipGalvanizingISO1461 && { hotDipGalvanizingISO1461: true }),
      ...(treatmentOptions.DASt022TrustZone?.length && {
        DASt022TrustZone: treatmentOptions.DASt022TrustZone,
      }),
      ...(treatmentOptions.onlyPickleZincAndBackBlack && { onlyPickleZincAndBackBlack: true }),
      ...(treatmentOptions.duroplexCoating && { duroplexCoating: true }),
      ...(treatmentOptions.thermoplexCoating && { thermoplexCoating: true }),
      ...(treatmentOptions.duroThermoShade && {
        duroThermoShade: treatmentOptions.duroThermoShade,
      }),
      ...(treatmentOptions.SPlusZ && { SPlusZ: true }),
      ...(treatmentOptions.ferrostyleCoatingN && { ferrostyleCoatingN: true }),
      ...(treatmentOptions.ferrostyleCoatingP && { ferrostyleCoatingP: true }),
      ...(treatmentOptions.ferrostyleShade && {
        ferrostyleShade: treatmentOptions.ferrostyleShade,
      }),
      ...(treatmentOptions.indoorApplication && { indoorApplication: true }),
      ...(treatmentOptions.outdoorApplication && { outdoorApplication: true }),
      ...(treatmentOptions.galvanicZincPlating && { galvanicZincPlating: true }),
      ...(treatmentOptions.brightChromePlating && { brightChromePlating: true }),
      ...(treatmentOptions.blueChromating && { blueChromating: true }),
      ...(treatmentOptions.EN1090 && { EN1090: true }),
      // eslint-disable-next-line max-len
      ...(treatmentOptions.corrosivityCategory?.length && {
        corrosivityCategory: treatmentOptions.corrosivityCategory,
      }),
      ...(treatmentOptions.preparationDegree?.length && {
        preparationDegree: treatmentOptions.preparationDegree,
      }),
      // eslint-disable-next-line max-len
      ...(treatmentOptions.preparationDegreeTest?.length && {
        preparationDegreeTest: treatmentOptions.preparationDegreeTest,
      }),
    };

    if (!Object.keys(formData.treatment).length) delete formData.treatment;
  }

  // More options - Special
  if (order.special && Object.keys(order.special).length) {
    const { special: specialOptions } = order;

    formData.special = {
      ...(specialOptions.cleanThread && { cleanThread: true }),
      ...(specialOptions.cleanThreadIn && { cleanThreadIn: true }),
      ...(specialOptions.cleanThreadOut && { cleanThreadOut: true }),
      ...(specialOptions.cleanThreadCount && { cleanThreadCount: specialOptions.cleanThreadCount }),
      ...(specialOptions.QS && { QS: true }),
      ...(specialOptions.measurementProtocol && { measurementProtocol: true }),
      ...(specialOptions.measurementProtocolAdvanced && { measurementProtocolAdvanced: true }),
      ...(specialOptions.drilledInternalHoles && { drilledInternalHoles: true }),
      ...(specialOptions.closeHoles && { closeHoles: true }),
      ...(specialOptions.closeHolesCount && { closeHolesCount: specialOptions.closeHolesCount }),
      ...(specialOptions.noAdditionallyDrilledHoles && { noAdditionallyDrilledHoles: true }),
      ...(specialOptions.removeZincOnly && { removeZincOnly: true }),
      ...(specialOptions.burnOffPaintOnly && { burnOffPaintOnly: true }),
      ...(specialOptions.removePaintAndZinc && { removePaintAndZinc: true }),
      ...(specialOptions.euroPalletsCount && { euroPallets: true }),
      ...(specialOptions.euroPalletsCount && { euroPalletsCount: specialOptions.euroPalletsCount }),
      ...(specialOptions.palletFramesCount && { palletFrames: true }),
      ...(specialOptions.palletFramesCount && {
        palletFramesCount: specialOptions.palletFramesCount,
      }),
      ...(specialOptions.halfPalletFramesCount && { halfPalletFrames: true }),
      ...(specialOptions.halfPalletFramesCount && {
        halfPalletFramesCount: specialOptions.halfPalletFramesCount,
      }),
      ...(specialOptions.disposablePalletsCount && { disposablePallets: true }),
      ...(specialOptions.disposablePalletsCount && {
        disposablePalletsCount: specialOptions.disposablePalletsCount,
      }),
      ...(specialOptions.otherDescription && { other: true }),
      ...(specialOptions.otherDescription && { otherDescription: specialOptions.otherDescription }),
    };

    if (!Object.keys(formData.special).length) delete formData.special;
  }

  // More options - Transport delivery
  if (order.transportDelivery && Object.keys(order.transportDelivery).length) {
    const { transportDelivery: transportDeliveryOptions } = order;

    formData.transportDelivery = {
      ...(transportDeliveryOptions.byGalvaswissLorry && { byGalvaswissLorry: true }),
      ...(transportDeliveryOptions.pickUpByHimself && { pickUpByHimself: true }),
    };

    if (!Object.keys(formData.transportDelivery).length) delete formData.transportDelivery;
  }

  // More options - Transport return delivery
  if (order.transportReturnDelivery && Object.keys(order.transportReturnDelivery).length) {
    const { transportReturnDelivery: transportReturnDeliveryOptions } = order;

    formData.transportReturnDelivery = {
      ...(transportReturnDeliveryOptions.byGalvaswissLorry && { byGalvaswissLorry: true }),
      ...(transportReturnDeliveryOptions.pickUpByHimself && { pickUpByHimself: true }),
    };

    if (!Object.keys(formData.transportReturnDelivery).length) {
      delete formData.transportReturnDelivery;
    }
  }

  if (order.orderComments) {
    formData.orderComments = order.orderComments;
  } else {
    formData.orderComments = '';
  }

  if (order.modifiedTime) {
    formData.modifiedTime = order.modifiedTime;
  } else if (order.createdTime) {
    formData.createdTime = order.createdTime;
  }

  return {
    imageDownloads,
    formData,
  };
}
