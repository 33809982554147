<template>
  <header class="w-full">
    <div class="w-full flex">
      <div class="self-end w-1/3 pt-5">
        <p class="text-m font-bold uppercase tracking-widest ml-7 mt-10">
          <Transition name="transition-fade-slow">
            <span v-if="humanReadableId">
              {{ $t('order_header.order_human_readable_id') }} {{ humanReadableId }}
            </span>
          </Transition>
        </p>
        <h2 class="hidden font-bold text-xl ml-7 print:block">{{ $t('order_header.order_label') }}</h2>
        <p class="font-bold text-sm leading-tight mt-14 ml-7">
          <span class="block">{{ $t('order_header.important') }}</span>
          {{ $t('order_header.important_second', { name: driverLine }) }}
        </p>
        <div class="h-55 flex items-center mt-4 mb-6 ml-20">
          <Transition name="transition-fade-fast">
            <img v-show="IFOLoaded || noTransitions" src="/images/ifo.png" alt="ifo" class="h-55"
              @load="IFOLoaded = true">
          </Transition>
          <Transition name="transition-fade-fast">
            <img v-show="SQSLoaded || noTransitions" src="/images/ISO-SQS.png" alt="ISO SQS" class="h-55 ml-45"
              @load="SQSLoaded = true">
          </Transition>
        </div>
      </div>
      <div class="w-1/3 flex flex-col items-center pt-30">
        <Transition name="transition-fade-fast">
          <img v-show="logoLoaded || noTransitions" src="/images/galvaswiss-brand-logo.png" alt="GalvaSwiss" class="h-40"
            @load="logoLoaded = true">
        </Transition>
        <Transition name="transition-fade-slow">
          <p v-show="locationName || noTransitions" class="h-20 text-xs text-gray-600 uppercase tracking-extreme mt-12">
            {{ locationName }}
          </p>
        </Transition>
      </div>
      <div class="w-1/3 mt-24 pl-28 mb-20 laptop:pl-50">
        <p class="flex flex-col text-tiny leading-tight">
          <span>{{ location.companyName }}</span>
          <span>{{ location.street }}</span>
          <span>{{ location.zipCode }} {{ location.city }}</span>
        </p>
        <p class="flex text-tiny leading-tight mt-18">
          <span class="w-50 flex flex-col">
            <span>{{ $t('order_header.phone') }}</span>
            <span>{{ $t('order_header.fax') }}</span>
            <span>{{ $t('order_header.email') }}</span>
            <span>{{ $t('order_header.web') }}</span>
          </span>
          <span class="flex flex-col">
            <span>{{ location.phoneNumber }}</span>
            <span>{{ location.fax }}</span>
            <a :href="'mailto:' + location.emailAddress" class="text-link underline">{{ location.emailAddress }}</a>
            <a target="_blank" :href="'https://' + location.website" class="text-link underline">{{
              location.website
            }}</a>
          </span>
        </p>
      </div>
    </div>

    <div class="w-full flex">
      <label class="w-1/3 flex flex-col border select-none cursor-pointer">
        <span class="form-label-primary py-3 border-b">{{ $t('order_header.billing_address_label') }}</span>
        <span class="flex-1 w-full flex items-center justify-center">
          <label class="flex-grow select-none cursor-pointer">
            <span class="flex-1 flex flex-col">
              <input disabled v-model="header.billingAddress.name" type="text" :placeholder="$t('order_header.company')"
                class="input-text w-full h-22 text-sm" @keydown.enter.prevent />
              <div>
                <input disabled v-model="header.billingAddress.firstName" type="text"
                  :placeholder="$t('order_header.firstname')" class="input-text w-1/2 h-22 text-sm"
                  @keydown.enter.prevent />
                <input disabled v-model="header.billingAddress.lastName" type="text"
                  :placeholder="$t('order_header.lastname')" class="input-text w-1/2 h-22 text-sm"
                  @keydown.enter.prevent />
              </div>
              <input disabled v-model="header.billingAddress.street" type="text"
                :placeholder="$t('order_header.enter_address')" class="input-text w-full h-22 text-sm"
                @keydown.enter.prevent />
              <div class="flex">
                <UISelect :is-disabled="true" :input-field="'model'" v-model="header.billingAddress.country"
                  class="billingAddressSelect" :options="countries" />
                <input disabled v-model="header.billingAddress.zipCode" type="text" :placeholder="$t('order_header.zip')"
                  class="input-text w-60 h-28 ml-5 text-sm text-left" @keydown.enter.prevent />
                <input disabled v-model="header.billingAddress.city" type="text" :placeholder="$t('order_header.city')"
                  class="input-text w-full h-28 ml-5 text-sm" @keydown.enter.prevent />
              </div>
            </span>
          </label>
        </span>
      </label>
      <div class="w-1/3 flex flex-col border-t border-b">
        <label class="flex-grow select-none cursor-pointer">
          <span class="form-label-primary w-full block py-3 border-b">
            <span>{{ $t('order_header.site_address') }}</span>
          </span>
          <span class="flex-1 flex flex-col">
            <input disabled v-model="header.shippingAddress.name" type="text" :placeholder="$t('order_header.company')"
              class="input-text w-full h-22 text-sm" @keydown.enter.prevent />
            <div>
              <input disabled v-model="header.shippingAddress.firstName" type="text"
                :placeholder="$t('order_header.firstname')" class="input-text w-1/2 h-22 text-sm"
                @keydown.enter.prevent />
              <input disabled v-model="header.shippingAddress.lastName" type="text"
                :placeholder="$t('order_header.lastname')" class="input-text w-1/2 h-22 text-sm" @keydown.enter.prevent />
            </div>
            <input disabled v-model="header.shippingAddress.street" type="text"
              :placeholder="$t('order_header.enter_address')" class="input-text w-full h-22 text-sm"
              @keydown.enter.prevent />
            <div class="flex">
              <UISelect :input-field="'model'" :is-disabled="true" v-model="header.shippingAddress.country"
                :options="countries" class="shippingAddressSelect" />
              <input disabled v-model="header.shippingAddress.zipCode" :placeholder="$t('order_header.zip')" type="text"
                class="input-text w-60 h-22 ml-5 text-sm text-left h-28" @keydown.enter.prevent />
              <input disabled v-model="header.shippingAddress.city" type="text" :placeholder="$t('order_header.city')"
                class="input-text w-full h-22 ml-5 text-sm h-28" @keydown.enter.prevent />
            </div>
          </span>
        </label>
      </div>
      <div class="w-1/3 flex-1 flex flex-col border">
        <label class="h-1/2 flex flex-col items-center justify-center pb-7 border-b select-none cursor-pointer">
          <span class="flex-shrink-0 font-bold text-sm text-center">{{ $t('order_header.offer_nr') }}</span>
          <input disabled v-model="header.offerNumber" type="text" class="input-text w-150 text-center mt-5 border-b"
            @keydown.enter.prevent />
        </label>
        <div class="flex items-center justify-center">
          <Transition name="transition-fade-slow">
            <div v-show="tooltipContentForPickupDateDeliveryDate" class="mr-10 print:hidden">
              <UITooltip :a11y="false" theme="galvaswiss" animation="shift-away" arrow arrow-type="round" distance="10"
                placement="left" interactive trigger="click focus mouseenter" :max-width="400">
                <template #trigger>
                  <button type="button" class="h-22 w-22 align-middle select-none">
                    <IconInformation class="icon-information h-full w-full" />
                  </button>
                </template>
                <p v-html="tooltipContentForPickupDateDeliveryDate"></p>
              </UITooltip>
            </div>
          </Transition>
        </div>
        <div class="h-1/2 flex items-center justify-center pb-7 select-none cursor-pointer">
          <div class="w-1/2 flex flex-col">
            <span class="flex-shrink-0 font-bold text-sm text-center">
              {{ $t('order_header.pickup_date') }}</span>
          </div>
          <div class="w-1/2 flex flex-col">
            <UIDatePicker disabled v-model="header.pickupDate" ref="pickup-date" is-required :locale="'de-CH'"
              :min-date="new Date()">
              <template v-slot="{ inputValue, inputEvents }">
                <input disabled class="input-text w-150 text-center mt-5" :value="inputValue" />
              </template>
            </UIDatePicker>
          </div>
        </div>
        <div class="h-1/2 flex items-center justify-center pb-7 select-none cursor-pointer">
          <div class="w-1/2 flex flex-col">
            <span class="flex-shrink-0 font-bold text-sm text-center">
              {{ (location.id === 'oberndorf') ? $t('order_header.delivery_date_nocommitment') :
                $t('order_header.delivery_date_main') }}</span>
          </div>
          <div class="w-1/2 flex flex-col">
            <UIDatePicker disabled v-model="header.deliveryDate" ref="delivery-date" is-required :locale="'de-CH'"
              :min-date="new Date()">
              <template v-slot="{ inputValue, inputEvents }">
                <input disabled class="input-text w-150 text-center mt-5" :value="inputValue" />
              </template>
            </UIDatePicker>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full flex mt-10">
      <div class="w-1/3 flex border">
        <label class="w-2/5 flex flex-col items-center border-r select-none">
          <span class="font-bold text-sm text-center select-none">{{ $t('order_header.date') }}</span>
          <input :value="toLocaleOrderDate" disabled
            class="flex-1 w-full text-center leading-tight text-secondary bg-white opacity-100 overflow-hidden"
            @keydown.enter.prevent />
        </label>
        <label class="w-3/5 flex flex-col items-center select-none cursor-pointer">
          <span class="font-bold text-sm text-center select-none">{{ $t('order_header.order_number') }}</span>
          <input disabled v-model="header.deliverySlipNumber" class="input-text flex-1 w-full text-center overflow-hidden"
            @keydown.enter.prevent />
        </label>
      </div>
      <div class="w-1/3 border-t border-b">
        <label class="flex flex-col items-center select-none cursor-pointer">
          <span class="font-bold text-sm text-center select-none">{{ $t('order_header.commision') }}</span>
          <input disabled v-model="header.commission" class="input-text flex-1 w-full text-center overflow-hidden"
            @keydown.enter.prevent />
        </label>
      </div>
      <div class="w-1/3 border">
        <label class="flex flex-col items-center select-none cursor-pointer">
          <span class="font-bold text-sm text-center select-none">{{ $t('order_header.order_nr') }}</span>
          <input disabled v-model="header.orderNumber" class="input-text flex-1 w-full text-center overflow-hidden"
            @keydown.enter.prevent />
        </label>
      </div>
    </div>
  </header>
</template>

<script>
import IconCalendar from '@/components/icon/IconCalendar';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { mask } from 'vue-the-mask';
import UIInputTextArea from '@/components/UI/input/UIInputTextArea';
import UISelect from '@/components/UI/UISelect';
import IconInformation from '@/components/icon/IconInformation';

export default {
  model: {
    prop: 'header',
    event: 'change',
  },
  props: {
    header: {
      type: Object,
      default: null,
    },
    humanReadableId: {
      type: String,
      default: '',
    },
    locationName: {
      type: String,
      default: 'Wellhausen',
    },
    noTransitions: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      default: null,
    },
    tooltips: {
      type: Object,
      default: null,
    },
  },
  components: {
    IconCalendar,
    UIDatePicker: DatePicker,
    UIInputTextArea,
    UISelect,
    IconInformation,
  },
  directives: {
    mask,
  },
  data: () => ({
    logoLoaded: false,
    IFOLoaded: false,
    SQSLoaded: false,
  }),
  watch: {
    imagesLoaded: {
      immediate: true,
      handler(value) {
        if (value) this.$emit('images-loaded');
      },
    },
  },
  methods: {
    getTooltipContent(tooltipID) {
      // Tooltip is not available
      if (!this.tooltips || !this.tooltips[tooltipID]) return '';

      // Tooltip doesn't have the current locale
      if (!this.tooltips[tooltipID][this.$i18n.locale]) {
        // Default de locale is not available
        if (!this.tooltips[tooltipID][defaultLocale]) return '';
        return this.tooltips[tooltipID][defaultLocale];
      }

      return this.tooltips[tooltipID][this.$i18n.locale];
    },
    showTooltip(tooltipName) {
      this[tooltipName] = true;
      this.showEn100Tooltip = true;
    },
  },
  computed: {
    tooltipContentForPickupDateDeliveryDate() {
      const tip = this.getTooltipContent('pickupDateDeliveryDate');
      return tip;
    },

    driverLine() {
      if (this.location.name === 'Oberndorf') {
        return 'Fahrer'
      } else {
        return 'Chauffeur'
      }
    },
    imagesLoaded() {
      return this.logoLoaded && this.IFOLoaded && this.SQSLoaded;
    },

    toLocaleOrderDate() {
      if (this.header.createdTime) {
        let date = this.header.createdTime;
        if (this.header.createdTime.toDate && typeof this.header.createdTime.toDate === 'function') {
          date = this.header.createdTime.toDate();
        }

        return date.toLocaleDateString('de-CH', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      } return this.header.orderDate;
    },
    countries() {
      // console.log(this.header.shippingAddress);
      return [{
        value: 'CH',
      }, {
        value: 'DE',
      }, {
        value: 'FR',
      }, {
        value: 'IT ',
      }];
    },

  },
};
</script>

<style>
label.billingAddressSelect>select {
  height: 28px;
}

label.shippingAddressSelect>select {
  height: 28px;
}
</style>
